import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductGalleryContainer as SourceProductGalleryContainer
} from 'SourceComponent/ProductGallery/ProductGallery.container';

/** @namespace Scandipwa/Component/ProductGallery/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Scandipwa/Component/ProductGallery/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Scandipwa/Component/ProductGallery/Container */
export class ProductGalleryContainer extends SourceProductGalleryContainer {
    __construct(props) {
        super.__construct(props);

        const { product: { id } } = props;

        this.state = {
            activeImage: 0,
            isZoomEnabled: false,
            prevProdId: id,
            isImageZoomPopupActive: false,
            isZoomImage: false
        };
    }

    containerFunctions = {
        onActiveImageChange: this.onActiveImageChange.bind(this),
        handleZoomChange: this.handleZoomChange.bind(this),
        disableZoom: this.disableZoom.bind(this),
        handleImageZoomPopupActiveChange: this.handleImageZoomPopupActiveChange.bind(this),
        onZoomImage: this.onZoomImage.bind(this)
    };

    onZoomImage() {
        const { isZoomImage } = this.state;

        this.setState({ isZoomImage: !isZoomImage });
    }

    containerProps = () => {
        const {
            activeImage,
            isZoomEnabled,
            isImageZoomPopupActive,
            isZoomImage
        } = this.state;
        const { product: { id }, isMobile } = this.props;

        return {
            gallery: this.getGalleryPictures(),
            productName: this._getProductName(),
            activeImage,
            isZoomEnabled,
            productId: id,
            isMobile,
            isImageZoomPopupActive,
            sliderRef: this.sliderRef,
            productCollectionLabel: this.getProductCollectionLabel(),
            isZoomImage
        };
    };

    getProductCollectionLabel() {
        // eslint-disable-next-line max-len
        const { product: { attributes: { product_collection: { attribute_options: attributeValue = {} } = {} } = {} } } = this.props;

        const productCollectionLabel = Object.values(attributeValue).length
            ? Object.values(attributeValue)[0].label : null;

        return productCollectionLabel;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGalleryContainer);
