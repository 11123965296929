import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';

import './ImageMagnifier.style';

/** @namespace Scandipwa/Component/ImageMagnifier/Component */
export class ImageMagnifierComponent extends PureComponent {
    static propTypes = {
        isActiveImageZoomable: PropTypes.bool.isRequired,
        gallery: PropTypes.arrayOf().isRequired,
        activeImage: PropTypes.number.isRequired,
        isMouseOnImage: PropTypes.number.isRequired
    };

    renderMagnifiedImage() {
        const { gallery, activeImage } = this.props;

        return (
            <div block="ImageMagnifier" elem="ImageWrapper">
                <Image
                  src={ gallery[activeImage].large.url }
                />
            </div>
        );
    }

    render() {
        const { isActiveImageZoomable, isMouseOnImage } = this.props;

        return isActiveImageZoomable && isMouseOnImage ? (
            <div block="ImageMagnifier">
                { this.renderMagnifiedImage() }
            </div>
        ) : null;
    }
}

export default ImageMagnifierComponent;
