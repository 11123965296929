import {
    CarouselScroll as SourceCarouselScroll
} from 'SourceComponent/CarouselScroll/CarouselScroll.component';

import './CarouselScroll.override.style';

/** @namespace Scandipwa/Component/CarouselScroll/Component */
export class CarouselScrollComponent extends SourceCarouselScroll {
    render() {
        return (
            <div block="CarouselScroll" ref={ this.carouselRef }>
                { this.renderContent() }
            </div>
        );
    }
}

export default CarouselScrollComponent;
