import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import Image from 'Component/Image';
import ImageMagnifier from 'Component/ImageMagnifier';
import ProductGalleryAdditional from 'Component/ProductGalleryAdditional';
import Slider from 'Component/Slider';
import VideoPopup from 'Component/VideoPopup';
import { ProductGallery as SourceProductGallery } from 'SourceComponent/ProductGallery/ProductGallery.component';
import { IMAGE_TYPE, PLACEHOLDER_TYPE, VIDEO_TYPE } from 'SourceComponent/ProductGallery/ProductGallery.config';
import { ProductType } from 'Type/ProductList';

import './ProductGallery.override.style';
/** @namespace Scandipwa/Component/ProductGallery/Component */
export class ProductGalleryComponent extends SourceProductGallery {
    static propTypes = {
        generalProduct: ProductType.isRequired,
        gallery: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([
                    PropTypes.number,
                    PropTypes.string
                ]),
                image: PropTypes.string,
                isPlaceholder: PropTypes.bool,
                alt: PropTypes.string,
                type: PropTypes.string,
                media_type: PropTypes.string
            })
        ).isRequired,
        productId: PropTypes.number,
        isZoomEnabled: PropTypes.bool.isRequired,
        activeImage: PropTypes.number.isRequired,
        onActiveImageChange: PropTypes.func.isRequired,
        handleZoomChange: PropTypes.func.isRequired,
        registerSharedElementDestination: PropTypes.func.isRequired,
        disableZoom: PropTypes.func.isRequired,
        handleImageZoomPopupActiveChange: PropTypes.func.isRequired,
        isMobile: PropTypes.bool.isRequired,
        isImageZoomPopupActive: PropTypes.bool.isRequired,
        isZoomImage: PropTypes.bool.isRequired,
        onZoomImage: PropTypes.func.isRequired
    };

    renderProductCollection() {
        const { productCollectionLabel } = this.props;

        return productCollectionLabel ? (
            <div
              block="ProductGallery"
              elem="ProductCollection"
              mods={ {
                  color: productCollectionLabel.toLowerCase()
              } }
            >
                { productCollectionLabel }
            </div>
        ) : null;
    }

    handleSliderClick = () => {
        const {
            isImageZoomPopupActive,
            handleImageZoomPopupActiveChange,
            gallery,
            activeImage,
            onZoomImage
        } = this.props;

        const { media_type } = gallery[activeImage];
        if (media_type === VIDEO_TYPE) {
            return;
        }
        if (isImageZoomPopupActive) {
            onZoomImage();
        } else {
            handleImageZoomPopupActiveChange(true);
        }
    };

    renderImage(mediaData, index) {
        const {
            isZoomImage
        } = this.props;

        const {
            base: { url: baseSrc } = {},
            large: { url: largeSrc } = {}
        } = mediaData;

        const style = isZoomImage ? { height: '100%' } : {};
        const src = isZoomImage ? largeSrc : baseSrc;

        return (
            <Image
              key={ index }
              src={ src }
              ratio="custom"
              mix={ {
                  block: 'ProductGallery',
                  elem: 'SliderImage',
                  mods: { isPlaceholder: !src }
              } }
              isPlaceholder={ !src }
              style={ style }
            />
        );
    }

    renderSlide(media, index) {
        const { media_type } = media;

        switch (media_type) {
        case IMAGE_TYPE:
            return this.renderImage(media, index);
        case VIDEO_TYPE:
            return this.renderVideo(media, index);
        case PLACEHOLDER_TYPE:
            return this.renderPlaceholder(index);
        default:
            return null;
        }
    }

    renderSlider() {
        const {
            gallery,
            activeImage,
            isZoomEnabled,
            onActiveImageChange,
            isImageZoomPopupActive,
            sliderRef
        } = this.props;

        const mods = {
            isImageZoomPopupActive,
            isZoomInCursor: !isImageZoomPopupActive
        };

        return (
            <div
              ref={ this.imageRef }
              block="ProductGallery"
              elem="SliderWrapper"
              mods={ mods }
            >
                <meta itemProp="image" content={ this.getImageUrl() } />
                <Slider
                  sliderRef={ sliderRef }
                  mix={ { block: 'ProductGallery', elem: 'Slider', mods } }
                  showCrumbs={ false }
                  showArrows={ isImageZoomPopupActive }
                  activeImage={ activeImage }
                  onActiveImageChange={ onActiveImageChange }
                  isInteractionDisabled={ isZoomEnabled }
                  onClick={ this.handleSliderClick }
                  sliderHeight={ isImageZoomPopupActive ? '100%' : 0 }
                  isHeightTransitionDisabledOnMount
                >
                    { gallery.map(this.renderSlide) }
                </Slider>
            </div>
        );
    }

    renderImageMangifier() {
        const {
            isMobile,
            productId,
            gallery,
            activeImage
        } = this.props;

        if (isMobile) {
            return null;
        }

        return (
            <ImageMagnifier
              productId={ productId }
              gallery={ gallery }
              activeImage={ activeImage }
            />
        );
    }

    renderAdditionalPictures() {
        const {
            gallery,
            isImageZoomPopupActive,
            activeImage,
            onActiveImageChange
        } = this.props;

        if (gallery.length === 1) {
            return <div block="ProductGallery" elem="Additional" />;
        }

        return (
            <div block="ProductGallery" elem="Additional" mods={ { isImageZoomPopupActive } }>
                <ProductGalleryAdditional
                  activeItemId={ activeImage }
                  onChange={ onActiveImageChange }
                  showedItemCount={ 6 }
                >
                    { gallery.map(this.renderAdditionalPicture) }
                </ProductGalleryAdditional>
            </div>
        );
    }

    render() {
        return (
            <div block="ProductGallery">
                { this.renderProductCollection() }
                { this.renderSlider() }
                { this.renderImageMangifier() }
                { this.renderAdditionalPictures() }
                <VideoPopup />
            </div>
        );
    }
}

export default withRouter(ProductGalleryComponent);
