import {
    ImageZoomPopup as SourceImageZoomPopup
} from 'SourceComponent/ImageZoomPopup/ImageZoomPopup.component';

import './ImageZoomPopup.override.style';

/** @namespace Scandipwa/Component/ImageZoomPopup/Component */
export class ImageZoomPopupComponent extends SourceImageZoomPopup {
    // TODO implement logic
}

export default ImageZoomPopupComponent;
