import {
    CarouselScrollItem as SourceCarouselScrollItem
} from 'SourceComponent/CarouselScrollItem/CarouselScrollItem.component';

import './CarouselScrollItem.override.style';

/** @namespace Scandipwa/Component/CarouselScrollItem/Component */
export class CarouselScrollItemComponent extends SourceCarouselScrollItem {
    // TODO implement logic
}

export default CarouselScrollItemComponent;
