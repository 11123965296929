import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ImageMagnifier from './ImageMagnifier.component';

/** @namespace Scandipwa/Component/ImageMagnifier/Container */
export class ImageMagnifierContainer extends PureComponent {
    static propTypes = {
        productId: PropTypes.number.isRequired,
        gallery: PropTypes.arrayOf().isRequired,
        activeImage: PropTypes.number,
        zoomLevel: PropTypes.number
    };

    static defaultProps = {
        activeImage: [],
        zoomLevel: 4
    };

    state = {
        isMouseOnImage: 0
    };

    componentDidMount() {
        this.addMouseEvents();
    }

    componentWillUnmount() {
        const zoomableImage = document.getElementsByClassName('ProductGallery-Slider')[0];
        const new_zoomableImage = zoomableImage.cloneNode(true);
        zoomableImage.parentNode.replaceChild(new_zoomableImage, zoomableImage);
    }

    containerProps() {
        const { zoomLevel, activeImage, gallery } = this.props;
        const { isMouseOnImage } = this.state;
        return {
            isActiveImageZoomable: this.checkIsActiveImageZoomable(), activeImage, isMouseOnImage, zoomLevel, gallery
        };
    }

    addMouseEvents() {
        const zoomableImage = document.getElementsByClassName('ProductGallery-Slider')[0];

        if (!zoomableImage.classList.contains('ProductGallery-Slider_isImageZoomPopupActive')) {
            zoomableImage.addEventListener('mouseenter', () => {
                this.setState({
                    isMouseOnImage: true
                });
                this.getMouseCoordinates();
            });
            zoomableImage.addEventListener('touchstart', () => {
                this.setState({
                    isMouseOnImage: true
                });
                this.getMouseCoordinates();
            });

            zoomableImage.addEventListener('mouseleave', () => {
                this.setState({
                    isMouseOnImage: false
                });
            });
        }
    }

    checkIsActiveImageZoomable() {
        const { gallery, activeImage } = this.props;

        return gallery[activeImage] && gallery[activeImage].types && gallery[activeImage].types.includes('zoomable');
    }

    getMouseCoordinates() {
        const { activeImage } = this.props;

        if (this.checkIsActiveImageZoomable()) {
            const image = document.getElementsByClassName('Slider-Wrapper')[0]
                .children[activeImage].children[0];

            image.addEventListener('mousemove', (e) => {
                const elem = e.currentTarget;
                const { top, left } = elem.getBoundingClientRect();
                const x = e.pageX - left - window.scrollX;
                const y = e.pageY - top - window.scrollY;
                this.magnifyImage(x, y);

                return [x, y];
            });
        }
    }

    magnifyImage(x_pos, y_pos) {
        const { zoomLevel } = this.props;
        const component = document.getElementsByClassName('ImageMagnifier')[0];
        const productGallerySlide = document.getElementsByClassName('Slider-Wrapper')[0];

        const imageContainer = component.getElementsByClassName('ImageMagnifier-ImageWrapper')[0]
            .getElementsByClassName('Image_ratio_square')[0];

        const image = imageContainer.getElementsByClassName('Image-Image')[0];

        // eslint-disable-next-line no-magic-numbers
        imageContainer.style.width = `${window.innerHeight * 0.38}px`;
        const slideWidth = productGallerySlide.offsetWidth;
        const slideHeight = productGallerySlide.offsetHeight;

        const containerWidth = parseInt(imageContainer.style.width, 10);
        const containerHeight = containerWidth;

        image.style.width = `${slideWidth * zoomLevel}px`;
        image.style.height = `${slideHeight * zoomLevel}px`;
        image.style.left = `${-x_pos * zoomLevel + containerWidth / 2}px`;
        image.style.top = `${-y_pos * zoomLevel + containerHeight / 2}px`;
    }

    render() {
        return (
            <ImageMagnifier
              { ...this.containerProps() }
            />
        );
    }
}

export default ImageMagnifierContainer;
