import React, {
    Children,
    Component,
    createRef
} from 'react';
import ReactSlick from 'react-slick';

/** @namespace Scandipwa/Component/ProductGalleryAdditional/Component */
export class ProductGalleryAdditionalComponent extends Component {
    additionalSlider = createRef();

    componentDidMount() {
        this.setAdditionalSlide();
    }

    componentDidUpdate(prevProps,) {
        const { activeItemId: prevActiveItemId } = prevProps;
        const { activeItemId } = this.props;

        if (this.additionalSlider.current && activeItemId !== prevActiveItemId) {
            this.setAdditionalSlide(activeItemId);
        }
    }

    setAdditionalSlide(index) {
        this.additionalSlider.current?.slickGoTo(index);
    }

    setAdditionalSliderRef(ref) {
        if (!this.additionalSlider.current) {
            this.additionalSlider.current = ref;
        }
    }

    render() {
        const {
            children,
            onChange,
            showedItemCount
        } = this.props;

        const sliderSettings = {
            dots: false,
            arrows: false,
            infinite: false,
            pagination: false,
            slidesToShow: showedItemCount,
            slidesToScroll: 1,
            vertical: false,
            verticalSwiping: false,
            swipeToSlide: false,
            lazyLoad: false,
            swipe: false,
            waitForAnimate: false
        };

        return (
            <ReactSlick { ...sliderSettings } ref={ this.setAdditionalSliderRef.bind(this) }>
                { Children.map(children, (child, index) => (
                    <div key={ index } onClick={ () => onChange(index) }>
                        { child }
                    </div>
                )) }
            </ReactSlick>
        );
    }
}

export default ProductGalleryAdditionalComponent;
